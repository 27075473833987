import React from 'react'
import Logo from "../assets/images/Getxlogo.png"
import "../assets/css/style2.css"
import { CgEye } from "react-icons/cg";
import { GoEyeClosed } from "react-icons/go";
import { FaUserLock } from "react-icons/fa";
import { useState } from 'react';
import OtpInput from 'react-otp-input';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../seperate/MakeApiRequest";
import 'react-toastify/dist/ReactToastify.css';
import { FaUserCircle } from "react-icons/fa";

function Signup() {
  const [otp, setOtp] = useState('');
  const Navigate = useNavigate();
  const [showPass, setShowPass] = useState(false)

  const toastNotiy = (notify, data) => {
    const styleOption = {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      style: {
        backgroundColor: '#808080',
      },
      bodyStyle: {
        fontWeight: 'bold',
        color: '#ffffff',
      },
    }
    if (notify === "success") {
      return toast.success(data, styleOption);
    } else if (notify === "warn") {
      return toast.warn(data, styleOption);
    } else {
      return toast.error(data, styleOption);
    }
  }
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues
  } = useFormik({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      otp: 0
    },
    validationSchema: Yup.object().shape({
      userName: Yup.string().required(" User Name is required!"),
      email: Yup.string()
        .email("Please enter valid email!")
        .required("E-mail is required!"),
      password: Yup.string().required("Password Is Required!")
    }),
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        const payload = {
          userName: values.userName,
          email: values.email,
          password: values.password,
          otp: otp
        };
        let params = {
          url: "register",
          method: "POST",
          data: payload
        }
        await makeApiRequest(params).then((res) => {
          if (res.status && res.type === 1) {
            toast.success(res.message)
            resetForm();
            setTimeout(() => Navigate('/'), 2000)
          } else if (res.status && res.type === 0) {
            toast.success(res.message)
          } else {
            toast.error(res.message)
          }
        })
      } catch {
        console.log("error");
      }
    },
  });
  return (

    <div>
      <ToastContainer />
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand" href="/"><img src={Logo} alt="Logo" /></a>
        </div>
      </nav>
      <div className="container new-log-reg-forms">
        <div>
          <div className="row my-5">
            <div className="">
              <div className="col-lg-4  mx-auto position-relative">
                <div className="new-lg-sq-box-01 "></div>
                <div className="new-full-login-box ">
                  <ul class="nav nav-tabs new-ul-btm-01" id="myTab" role="tablist">
                    <Link to="/signup" class="nav-item new-log-reg-child-1" role="presentation">
                      <button class="nav-link active new-log-reg-btn-1 py-2 text-white">E-mail</button>
                    </Link>
                    <Link to="/signup-ph" class="nav-item new-log-reg-child-2" role="presentation">
                      <button class="nav-link  new-log-reg-btn-2 py-2 text-white">Phone Number</button>
                    </Link>
                  </ul>
                  <div class="p-4">
                    <form className="">
                      <div className="text-center">
                        <p className="mb-auto new-eagle-log-ctn text-white">SIGNUP</p>
                      </div>

                      <div class="mb-4">
                        <div className="position-relative">
                          <label for="exampleInputEmail1" class="form-label"></label>
                          <input
                            type="email"
                            class="form-control new-bg-int-box py-2 text-white"
                            placeholder="User Name"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name='userName'
                            value={values.userName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={touched.userName && errors.userName ? true : false}
                          />
                          <FaUserCircle className="new-mail-lock-icon" />
                        </div>
                        {touched.userName && errors.userName && (
                          <small className="error">
                            {errors.userName}
                          </small>
                        )}
                      </div>

                      <div class="mb-4">
                        <div className="position-relative">
                          <label for="exampleInputEmail1" class="form-label"></label>
                          <input
                            type="email"
                            class="form-control new-bg-int-box py-2 text-white"
                            placeholder="E-mail"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            name='email'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={touched.email && errors.email ? true : false}
                          />
                          <FaUserLock className="new-mail-lock-icon" />
                        </div>
                        {touched.email && errors.email && (
                          <small className="error">
                            {errors.email}
                          </small>
                        )}
                      </div>

                      <div class="mb-4 mt-5">
                        <div className="position-relative">
                          <input
                            type={showPass ? "text" : "password"}
                            class="form-control py-2 new-bg-int-box  text-white "
                            placeholder="Password"
                            id="exampleInputPassword1"
                            name='password'
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            invalid={touched.password && errors.password ? true : false}
                          />
                          {showPass ? <CgEye className="new-bleeding-icon" onClick={() => setShowPass(!showPass)} /> : <GoEyeClosed className="new-bleeding-icon" onClick={() => setShowPass(!showPass)} />}
                        </div>
                        {touched.password && errors.password && (
                          <small className="error">
                            {errors.password}
                          </small>
                        )}
                      </div>
                      <div className=' text-end my-2'>
                        <p>
                          <a class="btn new-get-otp-btn-10" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" onClick={handleSubmit}>
                            GET CODE
                          </a>
                        </p>
                        <div class="collapse" id="collapseExample">
                          <div class="card card-body  text-start new-child-auth-box">
                            <p className="mb-auto" style={{ fontSize: "13px" }}>Enter OTP</p>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              renderSeparator={<span></span>}
                              renderInput={(props) => <input {...props} />}
                              inputStyle="otp-input"
                              containerStyle="otp-input-container"
                            />
                            <div className='text-end mt-5'>
                              <button className='new-get-otp-cls-btn mt-2' type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" onClick={handleSubmit}>submit</button>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="new-log-auth-01 mb-4">
                          <p className="mb-auto" style={{ fontSize: "13px" }}>Authenticator code</p>
                          <OtpInput

                            value={auth}
                            onChange={setauth}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle="otp-input"
                            containerStyle="otp-input-container"
                          />
                        </div>
                        <div class="mb-4">
                          <div className="position-relative">
                            <input type="text" class="form-control py-2 new-bg-int-box text-white border-top-0 border-end-0 border-start-0" style={{ borderRadius: "0px" }} placeholder="client code" id="exampleInputPassword1" />
                          </div>
                        </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup;