const Config = {
    backend_Url: "https://mm-api.b6evsdkvkoag.getx.exchange/",
    //backend_Url: "http://localhost:4000/",
    
    // GetxTickerEndpoint: "https://api.bitnevex.com/webapi/v3/bsinessApi/ticker-one?pair=",
    // GetxSymbolsEndpoint: "https://api.bitnevex.com/webapi/v3/bsinessApi/ticker"


    GetxTickerEndpoint: "https://api.getx.exchange/webapi/v3/bsinessApi/ticker-one?pair=",
    GetxSymbolsEndpoint: "https://api.getx.exchange/webapi/v3/bsinessApi/ticker"



    //bitStorage_EndPoint: "https://api.bitstorage.finance/v1/public/ticker?pair=",
    //coinPark_EndPoint: "https://api.koinpark.com/publicApi/Single_trade_pair?market_pair=",
    // bitStrPairApi: "https://api.bitstorage.finance/v1/public/symbols",
    // coinParkPairApi: "https://api.koinpark.com/publicApi/markets",
    // bitStrPairApi: "https://api.bitstorage.finance/v1/public/symbols",
    // dexTradePairApi: "https://api.dex-trade.com/v1/public/symbols"

}

export default Config;