import React, { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../seperate/MakeApiRequest";
import { toastNotify } from "./common/common";
import { CgEye } from "react-icons/cg";
import { GoEyeClosed } from "react-icons/go";
import { FaMarkdown, FaUserLock } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import OtpInput from 'react-otp-input';
import { useNavigate } from "react-router-dom";
import Logo from "../assets/images/Getxlogo.png"
import { ToastContainer, toast } from "react-toastify";

function ForgetPass() {
    const [showOtp, setShowOtp] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showOtpContent, setShowOtpContent] = useState(false)
    const [showPassWord1, setShowPassWord1] = useState(false);
    const [showPassWord2, setShowPassWord2] = useState(false);
    const [otp, setOtp] = useState('');
    const Navigate = useNavigate();
    const [resetPass, setResetPass] = useState({
        email: "",
        otp: "",
        newPassword: "",
        confirmPassword: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setResetPass({ ...resetPass, [name]: value })
    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const validationCheck = async (formType) => {
        if (formType === 1) {
            if (!resetPass.email || resetPass.email == "") {
                toastNotify("error", "Please Enter You'r Mail Id");
            } else if (!isValidEmail(resetPass.email)) {
                toastNotify("error", "Please Enter Valid Mail Id");
            } else {
                return true;
            }
        } else if (formType === 2) {
            if (!resetPass.email || resetPass.email == "") {
                toastNotify("error", "Please Enter You'r Mail Id!");
            } else if (!isValidEmail(resetPass.email)) {
                toastNotify("error", "Please Enter Valid Mail Id");
            } else if (otp !== 0 && otp === "") {
                toastNotify("error", "Please Enter You'r OTP!");
            } else if (otp.length !== 6) {
                toastNotify("error", "Please Enter Correct OTP!");
            } else {
                return true;
            }
        } else {
            if (!resetPass.newPassword || resetPass.newPassword == "") {
                toastNotify("error", "Please Enter You'r New Password");
            } else if (!resetPass.confirmPassword || resetPass.confirmPassword == "") {
                toastNotify("error", "Please Enter You'r Confirm Password");
            } else if (resetPass.newPassword !== resetPass.confirmPassword) {
                toastNotify("error", "Please ReEnter You'r Correct New Password");
            } else {
                return true;
            }
        }
    }
    const handleForgetSubmit = async () => {
        try {
            const isValid = await validationCheck(1);
            if (isValid) {
                let params = {
                    url: "forgotPassword",
                    method: "POST",
                    data: resetPass
                }
                const res = await makeApiRequest(params);
                console.log("res", res);
                if (res.status) {
                    toastNotify("success", res.message);
                    setShowOtpContent(true)
                    setShowOtp(true);
                } else {
                    toastNotify("error", res.message)
                    setShowOtp(false)
                }
            }
        } catch (error) {
            console.log("Error");
        }
    }
    const handleChecOtp = async () => {
        try {
            const isValid = await validationCheck(2);
            if (isValid) {
                resetPass["otp"] = otp
                let params = {
                    url: "forgotPasswordCheck",
                    method: "POST",
                    data: resetPass
                }
                const res = await makeApiRequest(params);
                if (res.status && res.verified) {
                    toastNotify("success", res.message);
                    setShowOtp(false)
                    setShowPass(true)
                } else {
                    toastNotify("error", res.message)
                }
            }
        } catch (error) {
            console.log("Error");
        }
    }
    const handleResetPass = async () => {
        try {
            const isValid = await validationCheck(3);
            if (isValid) {
                resetPass["otp"] = otp
                let params = {
                    url: "resetPassword",
                    method: "POST",
                    data: resetPass
                }
                const res = await makeApiRequest(params);
                console.log("res", res);
                if (res.status) {
                    toastNotify("success", res.message);
                    setTimeout(() => Navigate("/"), 2000);
                } else {
                    toastNotify("error", res.message);
                }
            }
        } catch (error) {
            console.log("Error");
        }
    }
    return (
        <div>
            <ToastContainer />
            <nav class="navbar navbar-expand-lg">
                <div class="container">
                    <a class="navbar-brand" href="/"><img src={Logo} alt="Logo" /></a>
                </div>
            </nav>
            <div className="container new-log-reg-forms">
                <div>
                    <div className="row my-5">
                        <div className="">
                            <div className="col-lg-4  mx-auto position-relative">
                                <div className="new-lg-sq-box-01 "></div>
                                <div className="new-full-login-box ">
                                    <div class="p-4">
                                        <form className="">
                                            <div className="text-center">
                                                <p className="mb-auto new-eagle-log-ctn text-white">Forget Pass?</p>
                                            </div>
                                            {!showPass && (
                                                <div class="mb-4">
                                                    <div className="position-relative">
                                                        <label for="exampleInputEmail1" class="form-label"></label>
                                                        <input
                                                            type="email"
                                                            class="form-control new-bg-int-box py-2 text-white"
                                                            placeholder="E-mail"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            name='email'
                                                            value={resetPass.email}
                                                            onChange={handleChange}
                                                        />
                                                        <FaUserLock className="new-mail-lock-icon" />
                                                    </div>
                                                </div>
                                            )}
                                            {!showOtpContent && (
                                                <div className='text-end mt-2'>
                                                    <p>
                                                        <a class="btn new-get-otp-btn-10" onClick={() => handleForgetSubmit()}>
                                                            GET OTP
                                                        </a>
                                                    </p>
                                                </div>
                                            )}

                                            {showOtp && (
                                                <div>
                                                    <div class="card card-body  text-start new-child-auth-box">
                                                        <p className="mb-auto" style={{ fontSize: "13px" }}>Enter OTP</p>
                                                        <OtpInput
                                                            value={otp}
                                                            onChange={setOtp}
                                                            numInputs={6}
                                                            renderSeparator={<span></span>}
                                                            renderInput={(props) => <input {...props} />}
                                                            inputStyle="otp-input"
                                                            containerStyle="otp-input-container"
                                                        />
                                                        <div className='text-end '>
                                                            <button className='new-get-otp-cls-btn mt-2' type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample" onClick={handleChecOtp}>submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {showPass && (
                                                <>
                                                    <div class="mb-4">
                                                        <div className="position-relative">
                                                            <input
                                                                type={showPassWord1 ? "text" : "password"}
                                                                class="form-control py-2 new-bg-int-box text-white "
                                                                placeholder="Password"
                                                                id="exampleInputPassword1"
                                                                name='newPassword'
                                                                value={resetPass.newPassword}
                                                                onChange={handleChange}
                                                            />
                                                            {showPassWord1 ? <CgEye className="new-bleeding-icon" onClick={() => setShowPassWord1(!showPassWord1)} /> : <GoEyeClosed className="new-bleeding-icon" onClick={() => setShowPassWord1(!showPassWord1)} />}
                                                        </div>
                                                    </div>

                                                    <div class="mb-4">
                                                        <div className="position-relative">
                                                            <input
                                                                type={showPassWord2 ? "text" : "password"}
                                                                class="form-control py-2 new-bg-int-box text-white "
                                                                placeholder="Password"
                                                                id="exampleInputPassword1"
                                                                name='confirmPassword'
                                                                value={resetPass.confirmPassword}
                                                                onChange={handleChange}
                                                            />
                                                            {showPassWord2 ? <CgEye className="new-bleeding-icon" onClick={() => setShowPassWord2(!showPassWord2)} /> : <GoEyeClosed className="new-bleeding-icon" onClick={() => setShowPassWord2(!showPassWord2)} />}
                                                        </div>
                                                    </div>

                                                    <div class="mb-4">
                                                        <div className="position-relative">
                                                            <button class="form-control py-2 new-bg-int-box text-white" type="button" onClick={handleResetPass}>Reset</button>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ForgetPass;