
import Logo from "../assets/images/Getxlogo.png"
import "../assets/css/style2.css"
import { CgEye } from "react-icons/cg";
import { FaUserLock } from "react-icons/fa";
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { TbPhoneCalling } from "react-icons/tb";

function Login() {
  const [otp, setOtp] = useState('');
  return (
    <div className="App">
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand" href="/"><img src={Logo} alt="Logo" /></a>
        </div>
      </nav>
      {/* <========================================> */}
      <div className="container new-log-reg-forms">
        <div>
          <div className="row my-5">
            <div className="">
              <div className="col-lg-4  mx-auto position-relative mt-5">
                <div className="new-lg-sq-box-01 "></div>
                <div className="new-full-login-box p-5">

                  <form className="">

                    <div className="new-log-auth-01">
                      <p className="mb-auto" style={{ fontSize: "17px" }}>Enter your 6 Digit code</p>
                      <OtpInput

                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} />}
                        inputStyle="otp-input"
                        containerStyle="otp-input-container"
                      />
                    </div>
                    <div className="mt-5 text-center">
                      <button type="submit" class="btn text-white new-bg-int-box new-login-btn-01 w-100">Submit</button>
                    </div>

                  </form>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;