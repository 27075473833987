import React, { useState, useEffect } from 'react';
import { FaArrowRightToBracket } from "react-icons/fa6";
import Select from 'react-select';
import BitStorage from '../assets/images/bitstorage_finance_logo.jpeg';
import KoinPark from '../assets/images/coinpark.png';
import GETXlogo from '../assets/images/Getxlogo.png'
import { BsInfoCircle } from "react-icons/bs";
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { makeApiRequest } from "../seperate/MakeApiRequest"
import { ToastContainer, toast } from "react-toastify";
import axios from 'axios';
import Config from '../seperate/Config';
import { useNavigate, useParams } from 'react-router-dom';
import { toastNotify } from "./common/common";
import { useFormik } from 'formik';
import { number } from 'yup';

function Dashboardbot() {
  const navigate = useNavigate();
  const { botId } = useParams();
  const [selectedOption, setSelectedOption] = useState({ value: "KoinPark" });
  const [selectedOptionpair, setSelectedOptionpair] = useState(null);
  const [selectedOptionbot, setSelectedOptionbot] = useState({ value: "gain-bot" });
  const [pairs, setPairs] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(0);
  const [excuteTrue, setExcuteTrue] = useState(false);
  const [excuteDisable, setExcuteDisable] = useState(false);
  const {
    handleChange,
    handleSubmit,
    values,
    resetForm,
    setValues
  } = useFormik({
    initialValues: {
      botName: "",
      pair: "",
      exchange: "",
      apiKey: "",
      signature: "",
      secretKey: "",
      status: "Active",
      botType: "price-bot",
      amount: Number,
      minAmount: Number,
      maxAmount: Number,
      currentPrice: Number,
      staticPrice: Number,
      priceChangepercentage: Number,
      lowerLimit: Number,
      upperLimit: Number,
      volume: Number,
      minQuantity: Number,
      maxQuantity: Number,
      buyOrderInterval: Number,
      buyIntervalType: "Sec",
      buyOrderCount: Number,
      sellOrderInterval: Number,
      sellIntervalType: "Sec",
      sellOrderCount: Number,
      buyBelow: Number,
      sellAbove: Number,
      twentyfourhigh: Number,
      minSpread: Number,
      maxSpread: Number
    },
    onSubmit: async (values) => {
      try {
        console.log(values);
        botId && (values._id = botId);
        let params = {
          url: "activateBot",
          method: "POST",
          data: values
        }
        const getValidation = validationError();

        if (getValidation) {
          setExcuteDisable(true)
          const res = await makeApiRequest(params);
          if (res.status) {
            toastNotify("success", res.message);
            window.location.reload();
          } else {
            setExcuteDisable(false)
          }
        }
      } catch {
        console.log("error");
      }
    },
  });

  console.log({ values })
  useEffect(() => {
    const getBotData = async () => {
      try {
        if (botId != undefined) {
          const params = {
            url: "getBotData",
            method: "POST",
            data: { botId: botId }
          }
          const res = await makeApiRequest(params);
          if (res.status) {
            setValues(res.data);
            setSelectedOptionbot({ value: res.data.botType })
          }
        } else if (botId == undefined) {
          resetForm();
          setSelectedOptionbot({ value: "gain-bot" })
        } else {
          resetForm();
          setSelectedOptionbot({ value: "gain-bot" })
        };
      } catch (error) {
        console.log("Error");
      }
    };
    getBotData();
  }, [botId]);

  const validationError = () => {
    try {
      if (!values.botName) {
        toastNotify('error', "Please Enter Bot Name!");
        return false;
      }
      if (!values.exchange) {
        toastNotify('error', "Please Choose You'r Exchange!");
        return false;
      }
      if ((values.exchange == "KoinPark" && !values.apiKey) || (values.exchange == "BitStorage" && !values.apiKey)) {
        toastNotify('error', "Please Enter  You'r apiKey!");
        return false;
      }
      if (values.exchange == "BitStorage" && !values.secretKey) {
        toastNotify('error', "Please Enter  You'r secretKey!");
        return false;
      }
      if (!values.pair) {
        toastNotify('error', "Please Choose You'r Pair!");
        return false;
      }
      if (!values.botType) {
        toastNotify('error', "Please Choose You'r Bot Type!");
        return false;
      }
      if (values.botType === "price-bot") {
        if (isNaN(values.upperLimit)) {
          toastNotify('error', "Please Enter You'r Upper Limit!");
          return false;
        } if (isNaN(values.lowerLimit)) {
          toastNotify('error', "Please Enter You'r Lower Limit!");
          return false;
        }
        if (Number(values.upperLimit) < Number(values.lowerLimit)) {
          toastNotify("error", "Please Enter Upper Limit Should Be Greater Than Lower Limit");
          return false;
        }
        if (isNaN(values.minQuantity)) {
          toastNotify('error', "Please Enter You'r Minimum Quantity!");
          return false;
        }
        if (isNaN(values.maxQuantity)) {
          toastNotify('error', "Please Enter You'r Maximum Quantity!");
          return false;
        }
        if (Number(values.maxQuantity) <= Number(values.minQuantity)) {
          toastNotify("error", "Please Enter Maximum Quantity Should Be Greater Than Minimum Quantity!");
          return false;
        }
        if (isNaN(values.buyOrderInterval)) {
          toastNotify('error', "Please Enter You'r Buy InterVal Time!");
          return false;
        }
        if (values.buyOrderInterval <= 5 && values.buyIntervalType == "Sec") {
          toastNotify('error', "Interval Time Must Be More Then 5!");
          return false;
        }
        if (isNaN(values.sellOrderInterval)) {
          toastNotify('error', "Please Choose You'r Sell InterVal Time!");
          return false;
        }
        if (isNaN(values.staticPrice)) {
          toastNotify('error', "Please Choose You'r staticPrice");
          return false;
        }
        if (isNaN(values.priceChangepercentage)) {
          toastNotify('error', "Please Choose You'r priceChangepercentage");
          return false;
        }
        if (values.sellOrderInterval <= 5 && values.sellIntervalType == "Sec") {
          toastNotify('error', "Interval Time Must Be More Then 5!");
          return false;
        }
        return true;
      } else if (values.botType === "volume-bot") {
        if (isNaN(values.upperLimit)) {
          toastNotify('error', "Please Enter You'r Upper Limit!");
          return false;
        } if (isNaN(values.lowerLimit)) {
          toastNotify('error', "Please Enter You'r Lower Limit!");
          return false;
        }
        if (Number(values.upperLimit) <= Number(values.lowerLimit)) {
          toastNotify("error", "Please Enter Upper Limit Should Be Greater Than Lower Limit");
          return false;
        }
        if (isNaN(values.minQuantity)) {
          toastNotify('error', "Please Enter You'r Minimum Quantity!");
          return false;
        }
        if (isNaN(values.maxQuantity)) {
          toastNotify('error', "Please Enter You'r Maximum Quantity!");
          return false;
        }
        if (Number(values.maxQuantity) <= Number(values.minQuantity)) {
          toastNotify("error", "Please Enter Maximum Quantity Should Be Greater Than Minimum Quantity!");
          return false;
        }
        if (isNaN(values.buyOrderInterval)) {
          toastNotify('error', "Please Enter You'r Buy InterVal Time!");
          return false;
        }
        if (values.buyOrderInterval <= 5 && values.buyIntervalType == "Sec") {
          toastNotify('error', "Interval Time Must Be More Then 5!");
          return false;
        }
        if (isNaN(values.volume)) {
          toastNotify('error', "Please Enter Your Volume Of Trade!");
          return false;
        }
        return true
      } else {
        if (isNaN(values.minQuantity)) {
          toastNotify('error', "Please Enter You'r Minimum Quantity!");
          return false;
        }
        if (isNaN(values.maxQuantity)) {
          toastNotify('error', "Please Enter You'r Maximum Quantity!");
          return false;
        }
        if (Number(values.maxQuantity) <= Number(values.minQuantity)) {
          toastNotify("error", "Please Enter Maximum Quantity Should Be Greater Than Minimum Quantity!");
          return false;
        }
        if (isNaN(values.buyOrderInterval)) {
          toastNotify('error', "Please Enter You'r Buy InterVal Time!");
          return false;
        }
        if (values.buyOrderInterval <= 5 && values.buyIntervalType == "Sec") {
          toastNotify('error', "Interval Time Must Be More Then 5!");
          return false;
        }
        if (isNaN(values.minSpread)) {
          toastNotify('error', "Please Enter You'r Minumun Spread!");
          return false;
        }
        if (isNaN(values.maxSpread)) {
          toastNotify('error', "Please Enter You'r Maximum Spread!");
          return false;
        }
        if (Number(values.minSpread) >= Number(values.maxSpread)) {
          toastNotify("error", "Please Enter Maximum Spread Should Be Greater Than Minimum Spread!");
          return false;
        }
        return true;
      }
    } catch (error) {
      toastNotify('success', "Validation Error!");
    }
  }
  const exchangeoptions = [
    { value: 'GETX', label: <div className='d-flex flex-row align-items-center gap-2'><img className='select-exc-image-1' src={GETXlogo} alt='KoinPark' /><span>GETX</span></div> }
    // { value: 'KoinPark', label: <div className='d-flex flex-row align-items-center gap-2'><img className='select-exc-image-1' src={KoinPark} alt='KoinPark' /><span>KoinPark</span></div> },
    // { value: 'BitStorage', label: <div className='d-flex flex-row align-items-center gap-2'><img className='select-exc-image-1' src={BitStorage} alt='BitStorage' /><span>BitStorage</span></div> },
  ];

  const pairoptions = pairs.map((data) => ({
    value: `${data.pair}`,
    label: (
      <div className='d-flex flex-row align-items-center gap-1'>
        <span>{data.pair}</span>
      </div>
    ),
  }));


  const botoptions = [
    { value: 'price-bot', label: "Price Bot" },
    { value: 'volume-bot', label: "Volume Bot" },
    { value: 'spread-bot', label: "Spread Bot" },
  ];
  const volumebotcurroptions = [
    { value: 'dcx', label: "DCX" },
    { value: 'usdt', label: "USDT" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'black' : 'black',
      background: state.isSelected ? '#1bebe436' : 'transparent',
    }),
    control: (base, state) => ({
      ...base,
      color: "red",
      background: "transparent",
      boxShadow: 'none',
      borderColor: state.isSelected ? "#ffffff54" : "#ffffff54",
      padding: "6px",
      '&:hover': {
        borderColor: "#ffffff54"
      },

    }),
  }

  useEffect(() => {
    const saveSymbols = async () => {
      try {
        let price = 0;
        let pair = selectedOptionpair?.value;
        if (selectedOption?.value === "GETX") {
          const res = await axios.get(`${Config.GetxTickerEndpoint}${pair}`)
          const { status, data } = res.data;
          console.log(data, "====----===");
          if (data) {
            price = data.marketPrice
          }
        }
        // if (selectedOption?.value === "KoinPark") {
        //   const res = await axios.get(`${Config.coinPark_EndPoint}${pair}`)
        //   const { status, data } = res.data;
        //   if (status == 1) {
        //     price = data[0].last_price
        //   }
        // } else {
        //   const res = await axios.get(`${Config.bitStorage_EndPoint}${pair}`)
        //   const { status, data } = res.data;
        //   if (status) {
        //     price = data.last
        //   }
        // }
        setCurrentPrice(Number(price));
        setValues((prevData) => ({
          ...prevData,
          currentPrice: Number(price)
        }))
      } catch (error) {
        console.error("Error:", error);
      }
    }
    saveSymbols();
  }, [selectedOption, selectedOptionpair]);

  useEffect(() => {
    const getPairs = async () => {
      try {
        const params = {
          url: "get-pairs",
          method: "POST",
          data: { exchange: selectedOption.value }
        }
        const res = await makeApiRequest(params);
        console.log(res);
        if (res.status) {
          setPairs(res.data);
          const defaultPairValue = res.data.length > 0 ? `${res.data[0].pair}` : "";
          setSelectedOptionpair({ value: defaultPairValue });
        }
      } catch (error) {
        console.log("Something Went Wrong!");
      }
    }
    getPairs()
  }, [selectedOption]);

  const credentialOptions = {
    KoinPark: ["apiKey", "secretKey"],
    BitStorage: ["apiKey", "secretKey"],
    GETX: ["apiKey", "secretKey"],
  };

  return (
    <div className="">
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="dash-profile-create-bot-1">
                <div className='d-flex flex-row'>
                  <h5 className='dash-profile-text-1 mb-3'>Name of Bot</h5>
                </div>
                <div className='d-flex gap-3'>
                  <div class="d-flex bot-name-input-section">
                    <span class="input-group-text" id="basic-addon1"><FaArrowRightToBracket className='opacity-50' /></span>
                    <input type="text" value={values.botName} class="form-control" placeholder="Enter Bot Name" name='botName' onChange={handleChange} />
                  </div>
                </div>
              </div>

              <div className="dash-profile-create-bot-1 mt-3">
                <div className='row'>
                  <div className='col-lg-3'>
                    <p className='create-bot-text-1'>Select Exchange</p>
                  </div>
                  <div className='col-lg-4 text-white'>
                    <Select
                      name='exchange'
                      className='select-exchange-new-css'
                      styles={customStyles}
                      onChange={(e) => {
                        setValues(prevState => ({
                          ...prevState,
                          exchange: e.value
                        }));
                        setSelectedOption(e)
                      }}
                      value={botId && { label: values.exchange, value: values.exchange }}
                      options={exchangeoptions}
                      // defaultValue={exchangeoptions.find(option => option.value === "GETX")}
                      placeholder={<div>select any</div>}
                    />
                  </div>
                </div>
                {selectedOption && (
                  <div className='row mt-3 dash-profile-create-bot-1-1'>
                    <div className='col-lg-3'>
                      <p className='create-bot-text-1'>Bind API</p>
                    </div>
                    <div className='col-lg-4 text-white'>
                      {credentialOptions[selectedOption.value].map((credential, index) => (
                        <div key={index} class="mb-3">
                          <label for={credential} class="form-label fs-14">{credential.toUpperCase()}</label>
                          <input type="text" value={values[credential]} id={credential} class="form-control" name={credential} onChange={handleChange}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="dash-profile-create-bot-1 mt-3">
                <div className='row'>
                  <div className='col-lg-3'>
                    <p className='create-bot-text-1'>Select Pair</p>
                  </div>
                  <div className='col-lg-4'>
                    <Select
                      name='pair'
                      className='select-exchange-new-css'
                      styles={customStyles}
                      onChange={(data) => {
                        setValues(prevState => ({
                          ...prevState,
                          pair: data.value
                        }));
                        setSelectedOptionpair(data);
                      }}
                      value={botId ? { label: values.pair, value: values.pair } : selectedOptionpair}
                      options={pairoptions}
                      placeholder={<div>select any</div>}
                    />
                  </div>
                </div>
              </div>

              <div className="dash-profile-create-bot-1 mt-3">
                <div className='row'>
                  <div className='col-lg-3'>
                    <p className='create-bot-text-1'>Select Bot</p>
                  </div>
                  <div className='col-lg-4'>
                    <Select
                      name='botType'
                      className='select-exchange-new-css'
                      styles={customStyles}
                      onChange={(selectedBotName) => {
                        setValues((prevData) => ({ ...prevData, botType: selectedBotName.value }))
                        setSelectedOptionbot(selectedBotName);
                      }}
                      value={botId && { label: values.botType, value: values.botType }}
                      options={botoptions}
                      placeholder={<div>select any</div>}
                    />
                  </div>
                </div>
              </div>

              {selectedOptionbot?.value === 'price-bot' ? (
                <div className="dash-profile-create-bot-1 mt-3">
                  <div>
                    <div className='mb-0 mt-4'>
                      <p className='create-bot-text-1'>{selectedOptionbot?.label} <span><BsInfoCircle className='position-relative fs-18 Percentage-bot-tooltip-1' style={{ top: "-1px" }} /></span></p>
                      <Tooltip anchorSelect=".Percentage-bot-tooltip-1" place="top" style={{ backgroundColor: "#FFF", color: "#222" }}>
                        Buy ...
                      </Tooltip>
                    </div>
                    <div className='row mt-5'>
                      <div className='col'>
                        <div className='d-flex align-items-center gap-2'>
                          <span className='active-bot-table-text-1 bg-white text-dark'>i</span>
                          <span className='create-bot-text-1 fs-6 mb-0'>Percentage deviation</span>
                        </div>

                      </div>
                      <div className='col'>Current Price

                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" name='currentPrice' class="form-control" value={values.currentPrice ? values.currentPrice : currentPrice} onChange={handleChange} />
                          </div>

                        </div>
                      </div>
                      <div className='col'>Static Price

                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" name='staticPrice' class="form-control" value={values.staticPrice} onChange={handleChange} />
                          </div>

                        </div>
                      </div>

                      <div className='col'>Price change percentage
                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" name='priceChangepercentage' class="form-control" value={values.priceChangepercentage} onChange={handleChange} />
                          </div>
                        </div>
                      </div>


                      <div className='col'>
                        <p className='create-bot-text-2 mb-0'>Upper Limit <span><BsInfoCircle className='position-relative fs-18 Percent-upper-bot-tooltip-1' style={{ top: "-1px" }} /></span></p>
                        <Tooltip anchorSelect=".Percent-upper-bot-tooltip-1" place="top" style={{ backgroundColor: "#FFF", color: "#222" }}>
                          Buy ...
                        </Tooltip>
                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" class="form-control" value={values.upperLimit} name="upperLimit" onChange={handleChange} />
                          </div>
                          <div>
                            %
                          </div>
                        </div>
                      </div>
                      <div className='col'>
                        <p className='create-bot-text-2 mb-0'>Lower Limit <span><BsInfoCircle className='position-relative fs-18 Percent-lower-bot-tooltip-1' style={{ top: "-1px" }} /></span></p>
                        <Tooltip anchorSelect=".Percent-lower-bot-tooltip-1" place="top" style={{ backgroundColor: "#FFF", color: "#222" }}>
                          Buy ...
                        </Tooltip>
                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" class="form-control" value={values.lowerLimit} name='lowerLimit' onChange={handleChange} />
                          </div>
                          <div>
                            %
                          </div>
                        </div>
                      </div>


                    </div>
                    <div className='row mt-5'>
                      <div className='col-lg-3'>
                        <div className='d-flex gap-2 align-items-center'>
                          <span className='active-bot-table-text-1 bg-white text-dark'>ii</span>
                          <span className='create-bot-text-1 fs-6 mb-0'>Amount Range</span>
                        </div>
                      </div>
                      <div className='col-lg-8'>
                        <div className='row'>
                          <div className='col ms-auto'>
                            <p className='create-bot-text-2 mb-0'>Min Quantity</p>
                            <div className='dash-profile-create-bot-4-1'>

                              <input type="text" class="form-control" name='minQuantity' value={values.minQuantity} onChange={handleChange} />

                            </div>
                          </div>
                          <div className='col'>
                            <p className='create-bot-text-2 mb-0'>Max Quantity</p>
                            <div className='dash-profile-create-bot-4-1'>

                              <input type="text" class="form-control" name='maxQuantity' value={values.maxQuantity} onChange={handleChange} />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <div className='col-lg-3'>
                        <div className='d-flex gap-2 align-items-center'>
                          <span className='active-bot-table-text-1 bg-white text-dark'>iii</span>
                          <span className='create-bot-text-1 fs-6'>Frequency</span>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <p className='create-bot-text-2 mb-0'>For Every Buy Order</p>
                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" class="form-control" name='buyOrderInterval' value={values.buyOrderInterval} onChange={handleChange} />
                          </div>
                          <div style={{ width: "35%" }}>
                            <select class="form-select" aria-label="Default select example" name="buyIntervalType" onChange={handleChange}  >
                              <option value="Sec" selected>Sec</option>
                              <option value="Min">Min</option>
                              <option value="Hour">Hour</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <p className='create-bot-text-2 mb-0'>For Every Sell Order</p>
                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" class="form-control" name='sellOrderInterval' value={values.sellOrderInterval} onChange={handleChange} />
                          </div>
                          <div style={{ width: "35%" }}>
                            <select class="form-select" aria-label="Default select example" name='sellIntervalType' value={values.sellIntervalType} onChange={handleChange} >
                              <option value="Sec" selected>Sec</option>
                              <option value="Min">Min</option>
                              <option value="Hour">Hour</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-row gap-3 mt-3'>
                      <div className='ms-auto'>
                        <button type="button" class="create-bot-save-2" disabled={excuteDisable} onClick={handleSubmit}>
                          EXCUTE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {selectedOptionbot?.value === 'volume-bot' ? (
                <div className="dash-profile-create-bot-1 mt-3">
                  <div>
                    <div className='mb-0 mt-4'>
                      <p className='create-bot-text-1'>{selectedOptionbot?.label} <span><BsInfoCircle className='position-relative fs-18 Percentage-bot-tooltip-1' style={{ top: "-1px" }} /></span></p>
                      <Tooltip anchorSelect=".Percentage-bot-tooltip-1" place="top" style={{ backgroundColor: "#FFF", color: "#222" }}>
                        Buy ...
                      </Tooltip>
                    </div>
                    <div className='row align-items-center mt-5'>
                      <div className='col-lg-3'>
                        <div className='d-flex align-items-center gap-2'>
                          <span className='active-bot-table-text-1 bg-white text-dark'>i</span>
                          <span className='create-bot-text-1 fs-6 mb-0'>Percentage deviation</span>
                        </div>

                      </div>
                      <div className='col-lg-4'>Current Price

                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" name='currentPrice' class="form-control" value={currentPrice} onChange={handleChange} />
                          </div>

                        </div>
                      </div>
                      <div className='col-lg-5'>
                        <div className='row'>
                          <div className='col-lg-6 ms-auto'>
                            <p className='create-bot-text-2 mb-0'>Upper Limit <span><BsInfoCircle className='position-relative fs-18 Percent-upper-bot-tooltip-1' style={{ top: "-1px" }} /></span></p>
                            <Tooltip anchorSelect=".Percent-upper-bot-tooltip-1" place="top" style={{ backgroundColor: "#FFF", color: "#222" }}>
                              Buy ...
                            </Tooltip>
                            <div className='dash-profile-create-bot-4-1'>
                              <div class="">
                                <input type="text" class="form-control" value={values.upperLimit} name="upperLimit" onChange={handleChange} />
                              </div>
                              <div>
                                %
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-6'>
                            <p className='create-bot-text-2 mb-0'>Lower Limit <span><BsInfoCircle className='position-relative fs-18 Percent-lower-bot-tooltip-1' style={{ top: "-1px" }} /></span></p>
                            <Tooltip anchorSelect=".Percent-lower-bot-tooltip-1" place="top" style={{ backgroundColor: "#FFF", color: "#222" }}>
                              Buy ...
                            </Tooltip>
                            <div className='dash-profile-create-bot-4-1'>
                              <div class="">
                                <input type="text" class="form-control" value={values.lowerLimit} name='lowerLimit' onChange={handleChange} />
                              </div>
                              <div>
                                %
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-5 align-items-center'>
                      <div className='col-lg-3'>
                        <div className='d-flex gap-2 align-items-center'>
                          <span className='active-bot-table-text-1 bg-white text-dark'>ii</span>
                          <span className='create-bot-text-1 fs-6 mb-0'>Amount Range</span>
                        </div>

                      </div>
                      <div className='col-lg-8'>
                        <div className='row'>
                          <div className='col ms-auto'>
                            <p className='create-bot-text-2 mb-0'>Min Quantity</p>
                            <div className='dash-profile-create-bot-4-1'>
                              <div class="">
                                <input type="text" class="form-control" name='minQuantity' value={values.minQuantity} onChange={handleChange} />
                              </div>
                            </div>
                          </div>
                          <div className='col'>
                            <p className='create-bot-text-2 mb-0'>Max Quantity</p>
                            <div className='dash-profile-create-bot-4-1'>
                              <div class="">
                                <input type="text" class="form-control" name='maxQuantity' value={values.maxQuantity} onChange={handleChange} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-5 align-items-center'>
                      <div className='col-lg-3'>
                        <div className='d-flex gap-2 align-items-center'>
                          <span className='active-bot-table-text-1 bg-white text-dark'>iii</span>
                          <span className='create-bot-text-1 fs-6'>Frequency</span>
                        </div>
                      </div>
                      <div className='col-lg-4'>
                        <p className='create-bot-text-2 mb-0'>For Every Order</p>
                        <div className='dash-profile-create-bot-4-1'>
                          <div class="">
                            <input type="text" class="form-control" name='buyOrderInterval' value={values.buyOrderInterval} onChange={handleChange} />
                          </div>
                          <div style={{ width: "35%" }}>
                            <select class="form-select" aria-label="Default select example" name="buyIntervalType" onChange={handleChange}  >
                              <option value="Sec" selected>Sec</option>
                              <option value="Min">Min</option>
                              <option value="Hour">Hour</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className='col-lg-4'>
                        <p className='create-bot-text-2 mb-0'>Total Volume</p>
                        <div className='dash-profile-create-bot-4-1'>
                          <div style={{ width: "35%" }}>
                            <input type="text" class="form-control" name='volume' value={values.volume} onChange={handleChange} />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-row gap-3 mt-3'>
                      <div className='ms-auto'>
                        <button type="button" class="create-bot-save-2" disabled={excuteDisable} onClick={handleSubmit}>
                          EXCUTE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {selectedOptionbot?.value === 'spread-bot' ? (
                <div className="dash-profile-create-bot-1 mt-3">
                  <div className='mb-4'>
                    <p className='create-bot-text-1'>Spread Bot <span><BsInfoCircle className='position-relative fs-18 Spread-bot-tooltip-1' style={{ top: "-1px" }} /></span></p>
                    <Tooltip anchorSelect=".Volume-bot-tooltip-1" place="top" style={{ backgroundColor: "#FFF", color: "#222" }}>
                      Buy ...
                    </Tooltip>
                  </div>

                  <div className='row mt-4'>
                    <div className='col-lg-3'>
                      <div className='d-flex gap-2 align-items-center'>
                        <span class="active-bot-table-text-1 bg-white text-dark">i</span>
                        <span className='create-bot-text-2'>Amount Range</span>
                      </div>
                    </div>
                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col ms-auto'>
                          <p className='create-bot-text-2 mb-0'>Min Quantity</p>
                          <div className='dash-profile-create-bot-4-1'>
                            <div class="">
                              <input type="text" class="form-control" name='minQuantity' value={values.minQuantity} onChange={handleChange} />
                            </div>
                          </div>
                        </div>
                        <div className='col'>
                          <p className='create-bot-text-2 mb-0'>Max Quantity</p>
                          <div className='dash-profile-create-bot-4-1'>
                            <div class="">
                              <input type="text" class="form-control" name='maxQuantity' value={values.maxQuantity} onChange={handleChange} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mt-4'>
                    <div className='col-lg-3'>
                      <div className='d-flex gap-2 align-items-center'>
                        <span class="active-bot-table-text-1 bg-white text-dark">ii</span>
                        <span className='create-bot-text-2'>Frequency of trade</span>
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <p className='create-bot-text-1 fs-6 mb-0'>For Every</p>
                      <div className='dash-profile-create-bot-4-1'>
                        <div class="">
                          <input type="text" class="form-control" name='buyOrderInterval' value={values.buyOrderInterval} onChange={handleChange} />
                        </div>
                        <div style={{ width: "60%" }}>
                          <select class="form-select" aria-label="Default select example" name='buyIntervalType' value={values.buyIntervalType} onChange={handleChange} >
                            <option value="Sec" selected>Sec</option>
                            <option value="Min">Min</option>
                            <option value="Hour">Hour</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row mt-4'>
                    <div className='col-lg-3'>
                      <div className='d-flex gap-2 align-items-center'>
                        <span class="active-bot-table-text-1 bg-white text-dark">iii</span>
                        <span className='create-bot-text-2'>Spread</span>
                      </div>
                    </div>
                    <div className='col-lg-9'>
                      <div className='row'>
                        <div className='col-lg-6 ms-auto'>
                          <p className='create-bot-text-2 mb-0'>Min Spread</p>
                          <div className='dash-profile-create-bot-4-1'>
                            <div class="">
                              <input type="text" class="form-control" name='minSpread' value={values.minSpread} onChange={handleChange} />
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <p className='create-bot-text-2 mb-0'>Max Spread</p>
                          <div className='dash-profile-create-bot-4-1'>
                            <div class="">
                              <input type="text" class="form-control" name='maxSpread' value={values.maxSpread} onChange={handleChange} />
                            </div>
                            <div>
                              %
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-row gap-3 mt-3'>
                    <div className='ms-auto'>
                      <button type="button" class="create-bot-save-2"
                        disabled={excuteDisable}
                        onClick={handleSubmit}
                      >
                        EXCUTE
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Dashboardbot;
