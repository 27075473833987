import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeApiRequest } from "./MakeApiRequest";

const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const fetchToken = async () => {
            try {
                const params = {
                    url: "verify-token",
                    method: "GET",
                }
                const response = await makeApiRequest(params);
                console.log('response', response)
                if (response.status) {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                    localStorage.clear()
                    navigate('/');
                }
            } catch (error) {
                console.log("error:", error.message);
            }
        }
        fetchToken();
    }, []);
    return (
        <React.Fragment>
            {
                props.children
            }
        </React.Fragment>

    );
}
export default ProtectedRoute;