import React, { useEffect, useState } from 'react';
import Switch from "react-switch";
import { CiEdit } from "react-icons/ci";
import { makeApiRequest } from '../seperate/MakeApiRequest';
import { toast } from "react-toastify";
import ChangePass from "./ChangePass"
import { toastNotify } from "./common/common";
import { IoIosCopy } from "react-icons/io";
import Apple from "../assets/images/apple-store.png";
import Playstore from "../assets/images/play-store.png";
import $ from "jquery";
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from "react-router-dom";
import QrCode from 'react-qr-code';
import { RiDeleteBin6Fill } from "react-icons/ri";

const validationtfaSchema = yup.object({
  code: yup
    .string('Please enter the 2FA code')
    .required('2FA code is required'),
});

function Dashboardprofile(props) {
  const profile = props.profile;
  const [allBot, setAllBot] = useState([]);
  const [activeBot, setActiveBot] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [trueOrFalse, setTrueOrFalse] = useState(false);
  const [siteSettings, setSiteSettings] = useState({});
  const [TFAQRCode, setTFAQRCode] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    const getTFADetails = async () => {
      console.log(profile?.userName,"profile")
      if (profile) {
        let displayName = siteSettings?.siteName + " - ";
        if (profile?.userName) {
          displayName = displayName + profile?.userName;
        }
        if (profile?.email) {
          displayName = displayName ? displayName + "(" + profile?.email + ")" : profile?.email;
        }
        else if (profile?.phoneno) {
          displayName = displayName ? displayName + "(" + profile?.phoneno + ")" : profile?.phoneno;
        }
        let urlencoded = encodeURI('otpauth://totp/' + displayName + '?secret=' + profile.secretKey + '&issuer=' + siteSettings?.siteName);
        console.log(urlencoded)
        setTFAQRCode(urlencoded)
      }
    }
    getTFADetails();
  }, []);
  useEffect(() => {
    const getSiteSettings = async () => {
      try {
        const params = {
          url: "siteSettings",
          method: "GET"
        }
        const res = await makeApiRequest(params);
        const { status, data } = res;
        if (status) {
          setSiteSettings(data)
        }
      } catch (error) {
        console.log("Site Settings Error!");
      }
    }
    getSiteSettings();
  }, [])

  const fetchAllBot = async () => {
    try {
      let params = {
        url: "getUserBot",
        method: "GET"
      }
      const res = await makeApiRequest(params);
      setActiveBot(res?.activeBot)
      setAllBot(res?.allBot)
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const editStatus = async (data, id) => {
    const requireData = {
      _id: id,
      status: data === "deActive" ? "Active" : "deActive"
    }
    let params = {
      url: "editBotStatus",
      method: "POST",
      data: requireData
    }
    const res = await makeApiRequest(params);
    if (res.status) {
      toastNotify("success", res.message);
      await fetchAllBot()
    } else {
      toastNotify("error", res.message)
    }
  }

  useEffect(() => {
    fetchAllBot()
  }, []);

  const handleProfileSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      let params = {
        url: "updateUserProfile",
        method: "POST",
        data: formData,
        header: "image"
      }
      if (profileImage === null) {
        toastNotify("error", "Please Choose You'r Profile")
      }
      const response = await makeApiRequest(params);
      if (response.status) {
        toastNotify("success", response.message);

      }
    } catch (error) {
      console.log("Error");
    }
  }
  function openAuthenticator() {
    setTrueOrFalse(!trueOrFalse)
    $("#Authendicator").click()
  }



  const formik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: validationtfaSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        secret: profile?.secretKey,
        code: values.code
      };
      const params = {
        url: 'updateTFA',
        method: 'POST',
        data: data
      };
      const response = (await makeApiRequest(params));
      if (response.status) {
        if (response.type === 1) {
          localStorage.clear()
          navigate('/');
        } else {
          resetForm({ values: '' });
        }
        toast.success(response.message);
      } else {
        toast.error(response.message);
      };
    },
  });

  const deleteBot = async (id) => {
    try {
      const params = {
        url: `deleteBot/${id}`,
        method: 'POST',
      };
      const response = (await makeApiRequest(params));
      if (response.status) {
        toast.success(response.message);
        await fetchAllBot();
      } else {
        toast.error(response.message);
      };
    } catch (error) {
      console.log("Can't Delete This Bot");
    }
  }
  const handleChange = (e) => {
    console.log("e", e);
  }
  return (
    <div>
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-lg-9">
              <div className="dash-profile-active-bot-1 position-relative">
                <h5 className='dash-profile-text-1 mb-3 ms-3'>Active Bot</h5>
                <table class="table">
                  <tbody>
                    {
                      activeBot.length > 0 ?
                        (
                          <>
                            {activeBot.map((botData, index) => (
                              <tr key={index + 1}>
                                <td><span className='active-bot-table-text-1'>{index + 1}</span></td>
                                <td>{botData.exchange}</td>
                                <td style={{ width: "50px", opacity: "0.7" }}>-</td>
                                <td style={{ opacity: "0.8" }}>{botData.botName}</td>
                                <td><button className='view-active-bot-btn-1' onClick={() => {
                                  $("#v-pills-bot-tab").click();
                                  navigate(`/dashboard/${botData._id}`)
                                }}>View analytics</button></td>
                              </tr>
                            )
                            )}
                          </>
                        ) : (
                          <>
                            <h6 className='text-center'> No Active Bots Here ...</h6>
                          </>
                        )
                    }
                  </tbody>
                </table>
              </div>

              <div className="dash-profile-active-bot-2">
                <h5 className='dash-profile-text-1 mb-3 ms-1 mb-4'>Bot History</h5>
                <table class=" text-center new-alt-table-01">
                  <thead>
                    <tr  >
                      <th>S.No</th>
                      <th >Exchange</th>
                      <th >Bot Name</th>
                      <th >Status</th>
                      <th >Action</th>
                      <th >Delete Bot</th>
                    </tr>
                  </thead>
                  <tbody class="new-clr-box">
                    {allBot.length > 0 ? (
                      <>
                        {allBot.length > 0 && allBot.map((botData, index) => (
                          <tr key={index + 1}>
                            <td >{index + 1} </td>
                            <td >{botData.exchange}</td>
                            <td>{botData.botName} </td>
                            <td>{botData.status === "Active" ? "Active" : "Deactive"}</td>
                            <td onClick={() => editStatus(botData.status, botData._id)} className='cursor cursor-01'>{botData.status === "Active" ? "Stop" : "Start"}</td>
                            <td onClick={() => deleteBot(botData._id)} className='cursor'><RiDeleteBin6Fill /></td >
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr className='text-center'>
                        <td>
                          Bot History Not Available...
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-3">

              <div className="dash-profile-section-1">
                <div className=" text-cente">
                  <div className='ms-auto'>
                    <button type="button" class="create-bot-btn-1" onClick={() => {
                      $("#v-pills-bot-tab").click()
                    }}>Create New Bot</button>
                  </div>
                  <div className="mb-2">
                    {profile.profile && (
                      <img src={profile.profile} alt="profile" className="profile-image-1" data-bs-toggle="modal" data-bs-target="#imagemodel" />
                    )}
                  </div>
                  <button type="button" class="profile-btn-1" data-bs-toggle="modal" data-bs-target="#staticBackdrop">Change Profile</button>
                </div>
                <div className="d-flex flex-row mt-3 align-items-center">
                  <p className='mb-0 profile-text-1'>Authenticator</p>
                  <div className='ms-auto'>
                    <button className='auth-switch' onClick={openAuthenticator} data-bs-toggle="modal"
                      data-bs-target="#Authendicator">
                      <Switch
                        className="react-switch"
                        onChange={handleChange}
                        checked={profile.tfaenablekey !== "" ? true : false}
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="dash-profile-section-1">
                <div className="d-flex flex-row mt-1 align-items-center">
                  <p className='mb-0 profile-text-1'>Change Password</p>
                  <div className='ms-auto'>
                    <button type="button" class="profile-btn-2" data-bs-toggle="modal" data-bs-target="#changePass"><CiEdit /></button>
                  </div>
                </div>
                <ChangePass />
                <div className=" mt-1">
                  <p className='mb-0 profile-text-1'>Active Session:</p>
                  <p className='mb-0 profile-text-1 opacity-75 mt-1' style={{ fontSize: "12px" }}>IP : 202.53.6.49</p>
                  <p className='mb-0 profile-text-1 opacity-75' style={{ fontSize: "12px" }}>11.32 pm </p>
                </div>
              </div>
            </div>
          </div>
        </div >
      </section >

      {/* Proffile Change Modal */}
      <div class="modal fade" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <input
                className='upload-image'
                type='file'
                accept="image/*"
                onChange={(e) => setProfileImage(e.target.files[0])}
              />
              <button className='upload' onClick={(e) => handleProfileSubmit(e)} >upload</button>
            </div>
          </div>
        </div>
      </div>

      {/* Authendicator Modal */}
      <div className='mm-authenticator-page'>
        <div class="modal fade" id="Authendicator" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
          <div class="modal-dialog modal-lg">
            <div class="modal-content bg-transparent mm-new-modal-ctn">
              <div class="modal-header">
                <button type="button" class='btn-close' data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className='container'>
                  <div className='text-center'>
                    <h3>Google Authticator</h3>
                    <p>Download and install the Authenticator App</p>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='text-center'>
                        <div>
                          <img src={Apple} style={{ width: "50px", height: "60px" }} alt='apple' />
                          <p className='mb-auto mt-3'>Download from </p>
                          <h6 className='fw-bold'>App Store</h6>
                        </div>

                        <div className='mt-5'>
                          {profile.tfaenablekey === "" ? (
                            <><QrCode className="qr-bg"
                              size={256}
                              style={{ height: "auto", maxWidth: "100%", width: "30%" }}
                              value={TFAQRCode}
                              viewBox={"0 0 256 256"} /><p className='mt-3'>scan this QR Code in Authenticator App</p></>
                          ) : (
                            <div className="form-floating mt-5">
                              Your 2FA is enabled right now.
                            </div>
                          )}
                         
                        </div>
                        {/* <div className='d-flex justify-content-center align-items-center  mm-new-copy-scan'>
                          <p className='mb-auto me-2'>Gsevxrevterg</p>
                          <IoIosCopy />
                        </div>
                        <div>
                          <p style={{ textTransform: "capitalize", fontSize: "15px" }} className='mt-3'>save this key or you lose  access to your account</p>
                        </div> */}
                        <div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                      <div className='text-center'>
                        <div>
                          <img src={Playstore} style={{ width: "50px", height: "60px" }} alt='playstore' />
                          <p className='mb-auto mt-3'>Download from </p>
                          <h6 className='fw-bold'>App Store</h6>
                        </div>
                        <div className='mt-5'>
                          <form onSubmit={formik.handleSubmit}>
                            <input type="text" id="code" name="code"
                              className="bg-transparent mm-auth-input-cd"
                              autoComplete='off'
                              placeholder='Enter 2FA Code'
                              onChange={(e) => {
                                let value = e.target.value;
                                value = value.replace(/[^0-9]/g, '');
                                e.target.value = value;
                                formik.handleChange(e);
                              }}
                              value={formik.values.code}
                              onBlur={formik.handleBlur}
                              error={formik.touched.code && Boolean(formik.errors.code)}
                              helperText={formik.touched.code && formik.errors.code}
                            />
                            {formik.errors.code ? <small className="invalid-code error">{formik.errors.code}</small> : null}
                            <button className='mt-3  mm-auth-enable-btn'>{profile?.tfaenablekey !== '' ? 'Disable' : 'Enable'}</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* View Profile Modal*/}
      <div class="modal fade" id="imagemodel" tabindex="-1" aria-labelledby="imagemodel">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <img src={profile.profile} alt="profile" height={100} width={100} />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Dashboardprofile;
