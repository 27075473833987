
import Logo from "../assets/images/Getxlogo.png"
import "../assets/css/style2.css"
import { CgEye } from "react-icons/cg";
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import { TbPhoneCalling } from "react-icons/tb";
import { Link } from "react-router-dom";
import { GoEyeClosed } from "react-icons/go";


function Login() {
  const [otpph, setOtpph] = useState('');
  return (
    <div className="App">
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand" href="/"><img src={Logo} alt="Logo" /></a>
        </div>
      </nav>
      {/* <========================================> */}
      <div className="container new-log-reg-forms">
        <div>
          <div className="row my-5">
            <div className="">
              <div className="col-lg-4  mx-auto position-relative">
                <div className="new-lg-sq-box-01 "></div>
                <div className="new-full-login-box ">
                  <ul class="nav nav-tabs new-ul-btm-01" id="myTab" role="tablist">
                    <Link to="/" class="nav-item new-log-reg-child-1" role="presentation">
                      <button class="nav-link  new-log-reg-btn-1 py-2 text-white">E-mail</button>
                    </Link>
                    <Link to="/lg-ph" class="nav-item new-log-reg-child-2" role="presentation">
                      <button class="nav-link active new-log-reg-btn-2 py-2 text-white">Phone Number</button>
                    </Link>
                  </ul>
                  <div className="p-4">
                    <form className="">
                      <div className="text-center">
                        {/* <img src={Eagle} className="new-eagle-img-01"/> */}
                        <p className="mb-auto new-eagle-log-ctn text-white">login</p>
                      </div>
                      <div class="mb-5">
                        <div className="position-relative">
                          <label for="exampleInputEmail1" class="form-label"></label>
                          <input type="number" class="form-control text-white new-bg-int-box py-2" placeholder="Phone number" id="exampleInputEmail1" aria-describedby="emailHelp" />
                          {/* <FaUserLock  /> */}
                          <TbPhoneCalling className="new-mail-lock-icon" />
                        </div>
                      </div>
                      <div class="mb-4">
                        <div className="position-relative">
                          {/* <label for="exampleInputPassword1" class="form-label"></label> */}
                          <input type="password" class="form-control text-white py-2 new-bg-int-box text-white " placeholder="Password" id="exampleInputPassword1" />
                          <CgEye className="new-bleeding-icon" />

                        </div>
                      </div>
                      {/* <div class=" form-check d-flex align-items-center justify-content-between">
                          <div>
                            <input type="checkbox" class="form-check-input text-white " id="exampleCheck1" />
                            <label class="form-check-label text-white" for="exampleCheck1">Check me out</label>
                          </div>
                          <div className="">
                            <p className=" mb-auto text-white">forgot password</p>
                          </div>
                          
                        </div> */}
                      <div className="new-log-auth-01">
                        <p className="mb-auto" style={{ fontSize: "13px" }}>Authenticator code</p>
                        <OtpInput

                          value={otpph}
                          onChange={setOtpph}
                          numInputs={6}
                          renderSeparator={<span></span>}
                          renderInput={(props) => <input {...props} />}
                          inputStyle="otp-input"
                          containerStyle="otp-input-container"
                        />
                      </div>
                      <div className="mt-5 text-center">
                        <button type="submit" class="btn text-white new-bg-int-box new-login-btn-01 w-100">LOG IN</button>
                      </div>
                      {/* <img src={Eagle}/> */}
                    </form>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <====================================> */}
    </div>

  );
}

export default Login;