
import Logo from "../assets/images/Getxlogo.png"
import "../assets/css/style2.css"
import { CgEye } from "react-icons/cg";
import { FaUserLock } from "react-icons/fa";
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeApiRequest } from "../seperate/MakeApiRequest";
import { Link, useNavigate } from "react-router-dom";
import { GoEyeClosed } from "react-icons/go";

function Login() {

  const [otp, setOtp] = useState('');
  const Navigate = useNavigate()
  const [showPass, setShowPass] = useState(false);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors,
    values,
    resetForm,
    setValues
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      otp: 0,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter valid email!")
        .required("E-mail is required!"),
      password: Yup.string().required("Password Is Required!")
    }),
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        const payload = {
          email: values.email,
          password: values.password,
          otp: otp
        };
        let params = {
          url: "login",
          method: "POST",
          data: payload
        }
        await makeApiRequest(params).then((res) => {
          if (res.status && res.type === 0) {
            toast.success(res.message);
            localStorage.setItem("token", res.token)
            setTimeout(() => Navigate('/dashboard'), 2000)
          } else if (res.status && (res.type === 1 || res.type === 2)) {
            toast.warn(res.message);
            setShowOtpBox(true)
          } else {
            toast.error(res.message)
          }
        });
      } catch {
        console.log("error");
      }
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== undefined && token !== null && token != "") {
      Navigate("/dashboard")
    }
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <nav class="navbar navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand" href="/"><img src={Logo} alt="Logo" /></a>
        </div>
      </nav>
      <div className="container new-log-reg-forms">
        <div>
          <div className="row my-5">
            <div className="">
              <div className="col-lg-4  mx-auto position-relative">
                <div className="new-lg-sq-box-01 "></div>
                <div className="new-full-login-box ">
                  <ul class="nav nav-tabs new-ul-btm-01" id="myTab" role="tablist">
                    <Link to="/" class="nav-item new-log-reg-child-1" role="presentation">
                      <button class="nav-link active new-log-reg-btn-1 py-2 text-white">E-mail</button>
                    </Link>
                    {/* <Link to="/lg-ph" class="nav-item new-log-reg-child-2" role="presentation">
                      <button class="nav-link  new-log-reg-btn-2 py-2 text-white">Phone Number</button>
                    </Link> */}
                  </ul>

                  <div class="p-4">
                    <form className="">
                      {!showOtpBox && (
                        <>
                          <div className="text-center">
                            <p className="mb-auto new-eagle-log-ctn text-white">login</p>
                          </div>
                          <div class="mb-5">
                            <div className="position-relative">
                              <label for="exampleInputEmail1" class="form-label"></label>
                              <input
                                type="email"
                                class="form-control new-bg-int-box text-white py-2"
                                placeholder="E-mail"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={touched.email && errors.email ? true : false}
                              />
                              <FaUserLock className="new-mail-lock-icon" />
                            </div>
                            {touched.email && errors.email && (
                              <small className="error">
                                {errors.email}
                              </small>
                            )}
                          </div>
                          <div class="mb-4">
                            <div className="position-relative">
                              <input type={showPass ? "text" : "password"}
                                class="form-control text-white py-2 new-bg-int-box text-white "
                                placeholder="Password"
                                id="exampleInputPassword1"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={touched.password && errors.password ? true : false}
                              />
                              {showPass ? <CgEye className="new-bleeding-icon" onClick={() => setShowPass(!showPass)} /> : <GoEyeClosed className="new-bleeding-icon" onClick={() => setShowPass(!showPass)} />}
                            </div>
                            {touched.password && errors.password && (
                              <small className="error">
                                {errors.password}
                              </small>
                            )}
                          </div>


                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <a class="btn new-get-otp-btn-10" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" onClick={handleSubmit}>
                                GET CODE
                              </a>
                            </div>
                            <div><a href="/forGetPassword">Forgot Password?</a></div>
                          </div>
                        </>
                      )}

                      {showOtpBox && (
                        <>
                          <div className="new-log-auth-01">
                            <p className="mb-auto" style={{ fontSize: "13px" }}>Authenticator code</p>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              renderSeparator={<span></span>}
                              renderInput={(props) => <input {...props} />}
                              inputStyle="otp-input"
                              containerStyle="otp-input-container"
                            />
                          </div>
                          <div className="mt-5 text-center">
                            <button type="submit" class="btn text-white new-bg-int-box new-login-btn-01 w-100" onClick={handleSubmit}>LOG IN</button>
                          </div>
                        </>)}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default Login;