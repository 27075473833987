
import './assets/css/style.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,

} from "react-router-dom";
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Emailotp from './pages/Email-otp';
import Loginph from './pages/Login-ph';
// import Signupph from './pages/Signup-ph';
import { useEffect, useState } from 'react';
import { makeApiRequest } from './seperate/MakeApiRequest';
import ForgetPass from './pages/ForgetPass';
import BotForm from "./pages/Dashboard-bot";
import { jwtDecode } from "jwt-decode";
import ProtectedRoute from './seperate/ProductRouter';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/lg-ph" element={<Loginph />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/dashboard" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />
          <Route exact path="/dashboard/:botId" element={<ProtectedRoute> <Dashboard /> </ProtectedRoute>} />

          <Route exact path="/emailotp" element={<Emailotp />} />
          <Route exact path="/forGetPassword" element={<ForgetPass />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;