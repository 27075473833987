
import Logo from "../assets/images/Getxlogo.png"
import Dashboardprofile from "./Dashboard-profile"
import Dashboardbot from "./Dashboard-bot"
import { MdSpaceDashboard } from "react-icons/md";
import { BiSolidBot } from "react-icons/bi";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { BsCurrencyExchange } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AddPairs from "./AddPairs";
import { useEffect, useState } from "react";
import { makeApiRequest } from "../seperate/MakeApiRequest";


function Dashboard(props) {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({})
  const getUserProile = async () => {
    try {
      const userToken = localStorage.getItem("token");
      if (userToken !== undefined && userToken !== null && userToken !== "") {
        let params = {
          url: "getMyProfile",
          method: "GET"
        }
        const res = await makeApiRequest(params);
        console.log('res', res)
        setProfile(res?.data);
      }
    } catch (error) {
      console.log("Error:!", error);
    }
  }
  useEffect(() => {
    getUserProile();
  }, []);
  return (
    <div className="dashboard-overrall-section">
      <ToastContainer />
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="dashboard-left-section px-0">
              <div className="ps-2 pt-3">
                <img className="dashboard-left-navbar-logo" src={Logo} alt="Logo" />
              </div>
              <div class="mt-3">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button class="nav-link active" id="v-pills-dashboard-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-dashboard-home" type="button" role="tab" aria-controls="v-pills-dashboard-home" aria-selected="true" onClick={() => navigate("/dashboard")}><MdSpaceDashboard className="dashboard-left-icon-1" /><span className="position-relative" style={{ top: "2px" }}>Dashboard</span></button>
                  <button class="nav-link" id="v-pills-bot-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bot" type="button" role="tab" aria-controls="v-pills-bot" aria-selected="false"><BiSolidBot className="dashboard-left-icon-1" /><span className="position-relative" style={{ top: "2px" }}>Bot</span></button>
                  <button class="nav-link" id="v-pills-bot-tab" data-bs-toggle="pill" data-bs-target="#v-pills-add-pair" type="button" role="tab" aria-controls="v-pills-bot" aria-selected="false"><BsCurrencyExchange className="dashboard-left-icon-1" /><span className="position-relative" style={{ top: "2px" }}>Add Pairs</span></button>
                  <button class="nav-link" id="v-pills-report-bug-tab" data-bs-toggle="pill" data-bs-target="#v-pills-report-bug" type="button" role="tab" aria-controls="v-pills-report-bug" aria-selected="false" onClick={() => { localStorage.clear(); navigate("/") }}><FaArrowRightToBracket className="dashboard-left-icon-1" /><span className="position-relative" style={{ top: "2px" }}>Logout</span></button>
                </div>
              </div>
            </div>
            <div className="dashboard-right-section">
              <div class="tab-content" id="v-pills-tabContent">
                <div class="tab-pane fade show active" id="v-pills-dashboard-home" role="tabpanel" aria-labelledby="v-pills-dashboard-home-tab" tabindex="0"><Dashboardprofile profile={profile} /></div>
                <div class="tab-pane fade" id="v-pills-bot" role="tabpanel" aria-labelledby="v-pills-bot-tab" tabindex="0"><Dashboardbot /></div>
                <div class="tab-pane fade" id="v-pills-add-pair" role="tabpanel" aria-labelledby="v-pills-bot-tab" tabindex="0"><AddPairs /></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Dashboard;
