import { ToastContainer, toast } from "react-toastify";

export const toastNotify = (notify, data) => {
    const styleOption = {
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        style: {
            backgroundColor: 'gray',
        },
        bodyStyle: {
            fontWeight: 'bold',
            color: '#ffffff',
        },
    };
    if (notify === "success") {
        return toast.success(data, styleOption);
    } else if (notify === "warn") {
        return toast.warn(data, styleOption);
    } else {
        return toast.error(data, styleOption);
    }
};
