import React, { useEffect, useState } from 'react';
import { makeApiRequest } from '../seperate/MakeApiRequest';
import { toastNotify } from './common/common';
import $ from "jquery"
import { useNavigate } from 'react-router-dom';
function ChangePass() {
    const [passCre, setPass] = useState({
        oldPass: "",
        newPass: "",
        confirmPass: ""
    });
    const Navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPass({ ...passCre, [name]: value })
    }

    const validationCheck = async () => {
        if (!passCre.oldPass || passCre.oldPass == "") {
            toastNotify("error", "Please Enter You'r Old Password");
        } else if (!passCre.newPass || passCre.newPass == "") {
            toastNotify("error", "Please Enter You'r New Password");
        } else if (!passCre.confirmPass || passCre.confirmPass == "") {
            toastNotify("error", "Please Enter You'r Confirm Password");
        } else {
            return true;
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const validTrue = await validationCheck();
            if (validTrue) {
                let params = {
                    url: "changePass",
                    method: "POST",
                    data: passCre
                }
                const res = await makeApiRequest(params);
                if (res.status) {
                    toastNotify("success", res.message);
                    setPass({ 
                        oldPass: "",
                        newPass: "",
                        confirmPass: ""
                    });
                    $("#closePassModal").click()
                } else {
                    toastNotify("error", res.message);
                }
            }
        } catch (error) {
            console.log("Error");
        }
    }

    return (
        <>
            <div class="modal fade" id="changePass" tabindex="-1" aria-labelledby="changePassLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content custom-model-1">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalLabel">Change Password</h1>
                            <button type="button" id='closePassModal' class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='row mt-3 dash-profile-create-bot-1-1'>
                                    <div className='col-lg-4 text-white'>
                                        <div>
                                            <div class="mb-3">
                                                <label class="form-label fs-14">Old Password</label>
                                                <input class="form-control" type="text" name='oldPass' value={passCre.oldPass} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div>
                                            <div class="mb-3">
                                                <label class="form-label fs-14">New Password</label>
                                                <input class="form-control" type="text" name='newPass' value={passCre.newPass} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div>
                                            <div class="mb-3">
                                                <label class="form-label fs-14">Confirm Password</label>
                                                <input class="form-control" type="text" name='confirmPass' value={passCre.confirmPass} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div>
                                            <div class="mb-3">
                                                <button className='upload'>Change</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ChangePass;