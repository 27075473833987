import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { makeApiRequest } from "../seperate/MakeApiRequest";
import Select from 'react-select';
import BitStorage from '../assets/images/bitstorage_finance_logo.jpeg';
import KoinPark from '../assets/images/coinpark.png';
import GEtxlogo from '../assets/images/Getxlogo.png';
import axios from "axios";
import Config from "../seperate/Config";
function AddPairs() {
    const [pairs, setPairs] = useState([]);
    const [exchange, setExchange] = useState({ value: "KoinPark" });
    const [selectedPairs, setSelectedPairs] = useState("");
    const exchangeoptions = [
        { value: 'GETX', label: <div className='d-flex flex-row align-items-center gap-2'><img className='select-exc-image-1' src={GEtxlogo} alt='KoinPark' /><span>GETX</span></div> },
 
        // { value: 'KoinPark', label: <div className='d-flex flex-row align-items-center gap-2'><img className='select-exc-image-1' src={KoinPark} alt='KoinPark' /><span>KoinPark</span></div> },
        // { value: 'BitStorage', label: <div className='d-flex flex-row align-items-center gap-2'><img className='select-exc-image-1' src={BitStorage} alt='BitStorage' /><span>BitStorage</span></div> },
    ];
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'black' : 'black',
            background: state.isSelected ? '#1bebe436' : 'transparent',
        }),
        control: (base, state) => ({
            ...base,
            color: "red",
            background: "transparent",
            boxShadow: 'none',
            borderColor: state.isSelected ? "#ffffff54" : "#ffffff54",
            padding: "6px",
            '&:hover': {
                borderColor: "#ffffff54"
            },
        }),
    }

    useEffect(() => {
        const getPairs = async () => {
            let apiUrl;
            let key;
            switch (exchange.value) {
                case "GETX":
                    apiUrl = Config.GetxSymbolsEndpoint;
                     key = "pair";
                    break;
                // case "BitStorage":
                //     apiUrl = Config.bitStrPairApi;
                //     key = "pair";
                //     break;
                // default:
                //     apiUrl = Config.dexTradePairApi;
                //     key = "pair";
            }
            try {
                const res = await axios.get(apiUrl);
                console.log(res,"Asdasdasd");
                const arrData = res?.data?.data;
                if (Array.isArray(arrData)) {
                    const filteredPairs = arrData
                        // .filter(data => data[key].includes("DCX"))
                        .map(data => ({ value: data[key], label: data[key] }));
                        console.log(filteredPairs);
                    setPairs(filteredPairs);
                    setSelectedPairs(filteredPairs[0].value)
                }
            } catch (error) {
                console.error("Error fetching pairs:", error);
            }
        };
        getPairs();
    }, [exchange]);

    const handleSubmit = async () => {
        try {
            const payload = {
                exchange: exchange.value,
                pair: selectedPairs
            };
            let params = {
                url: "add-pairs",
                method: "POST",
                data: payload
            }
            await makeApiRequest(params).then((res) => {
                if (res.status) {
                    toast.success(res.message);
                    setTimeout(() => window.location.reload(), 2000)
                } else {
                    toast.error(res.message)
                }
            });
        } catch {
            console.log("error");
        }
    }
    return (
        <div className="App">
            <div className="container new-log-reg-forms">
                <div>
                    <div className="row my-5">
                        <div className="col-lg-4  mx-auto position-relative">
                            <div className="new-lg-sq-box-01 "></div>
                            <div className="new-full-login-box ">
                                <div class="p-4">
                                    <form>
                                        <div className="text-center">
                                            <p className="mb-auto new-eagle-log-ctn text-white">Add Pairs</p>
                                        </div>
                                        <div class="mb-4">
                                            <div className="position-relative">
                                                <Select
                                                    className='select-exchange-new-css'
                                                    styles={customStyles}
                                                    onChange={(e) => setExchange(e)}
                                                    defaultValue={exchangeoptions.find(option => option.value === "KoinPark")}
                                                    options={exchangeoptions}
                                                    placeholder={<div>select any</div>}
                                                />
                                            </div>
                                        </div>
                                        <div class="mb-4 select-opt-01">
                                            <div className="position-relative">
                                                <select onChange={(e) => setSelectedPairs(e.target.value)}
                                                    className='select-exchange-new-css-001'
                                                >
                                                    {pairs.map((data) => (
                                                        <option value={data.value} className='text-dark'>{data.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <p>
                                            <button class="btn new-get-otp-btn-10" onClick={handleSubmit}>
                                                ADD PAIR
                                            </button>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddPairs;